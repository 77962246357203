<template>
  <div class="d-flex justify-content-end align-items-end">
    <div
      v-background-image.contain="imagePath"
      class="eb-circle-image-wrapper"
      :style="{ '--size': size + 'px' }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CircleImage',
  props: {
    image: {
      type: Object,
      required: false,
      default: undefined,
    },
    /**
     * Size in pixel
     */
    size: {
      type: Number,
      default: 60,
    },
  },
  computed: {
    imagePath() {
      if (this.image) {
        return this.image.small_path
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss">
.eb-circle-image-wrapper {
  height: var(--size);
  width: var(--size);
  border-radius: 100%;
  overflow: hidden;
  mask-image: radial-gradient(white, black);
  //-webkit-mask-image: -webkit-radial-gradient(white, black);
}
</style>

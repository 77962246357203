
import Vue from 'vue'
import Modal from '@/shared/components/modals/Modal.vue'
import BaseInput from '@/shared/components/input/BaseInput.vue'
import { email } from 'vuelidate/lib/validators'
import Divider from '@/shared/components/utils/Divider.vue'
import RegisterHint from '@/shared/components/partials/RegisterHint.vue'

export default Vue.extend({
  name: 'LikeButton',
  components: {
    RegisterHint,
    Modal,
    BaseInput,
    Divider,
  },
  props: {
    isLiked: {
      type: Boolean,
      default: false,
    },
    isEnabled: {
      type: Boolean,
      default: false,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    showTextMobile: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showRegisterModal: false,
      email: '',
    }
  },
  computed: {
    loginPath(): string {
      // If we have something in our cart target checkout after login
      let targetPath = this.$route.fullPath

      return this.localePath({
        name: 'login',
        query: {
          target: targetPath,
          returnTo: this.$config.baseUrl + targetPath,
        },
      })
    },
  },
  mounted() {
    window.setTimeout(() => {
      const input = this.$refs.input as HTMLInputElement | null
      if (input) {
        input.focus()
      }
    }, 1000)
  },
  methods: {
    handleClick(e: MouseEvent) {
      if (this.isEnabled) {
        this.$emit('click', e)
      } else {
        this.showRegisterModal = true
      }
    },
  },
  validations() {
    return {
      email: {
        email,
      },
    }
  },
})


import Vue, { PropOptions } from 'vue'
import { ApiResourceBase, ResourceCollection } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Sorting } from '@anny.co/vue-jsonapi-orm/dist/builder/BaseQueryBuilder'
import DataCollection from '@/shared/components/collections/DataCollection.vue'

export default Vue.extend({
  name: 'OrmCollection',
  components: {
    DataCollection,
  },
  props: {
    /**
     * Resource Collection
     */
    collection: {
      type: Object,
      required: true,
    } as PropOptions<ResourceCollection<ApiResource>>,
    /**
     * Define if items should be requested
     */
    requestItems: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    /**
     * Columns for data table
     */
    columns: {
      type: Array,
      default: () => [],
    } as PropOptions<Record<string, any>[]>,
    defaultAttrs: {
      type: Object,
      default: () => ({}),
    } as PropOptions<Record<string, any>>,
    refreshOnMount: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
  },
  data() {
    return {
      editedItem: null as null | ApiResourceBase,
      showDetailModal: false,
    }
  },
  async mounted() {
    if (this.requestItems) {
      try {
        await this.collection.requestItems()
      } catch (e) {
        console.log(e)
      }
    }
  },
  methods: {
    /**
     * Sort items
     */
    async sortItems({ sort, order }: Sorting) {
      if (sort) {
        try {
          await this.collection.orderBy(sort, order)
        } catch (e) {
          console.log(e)
        }
      } else {
        await this.collection.orderBy('')
      }
    },
    /**
     * Load more items
     */
    async loadMoreItems() {
      try {
        await this.collection.nextPage()
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * Refresh table manually
     */
    async refresh() {
      try {
        await this.collection.requestItems()
      } catch (e) {
        console.log(e)
      }
    },
  },
})

<template>
  <div class="eb-divider" :class="{ 'full-width': noContent }">
    <slot name="content">
      <span class="eb-divider-text">
        <slot>
          {{ label }}
        </slot>
      </span>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Divider',
  props: {
    noContent: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">
.eb-divider {
  margin: 0.75rem 0;
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  & > span {
    position: relative;
    display: inline-block;
  }
  & > span:before,
  & > span:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: var(--light-border-color);
  }
  & > span:before {
    right: 100%;
    margin-right: 15px;
  }
  & > span:after {
    left: 100%;
    margin-left: 15px;
  }
  &.full-width {
    & > span:before {
      margin-right: 0;
    }
    & > span:after {
      margin-left: 0;
    }
  }
  &-text {
    color: var(--text-tertiary-color);
    font-size: var(--small-font-size);
    margin-bottom: 0;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}
</style>


import IntersectionObserver from '../utils/IntersectionObserver.vue'
import ActionDropdown from '../buttons/ActionDropdown.vue'
import MoreOptionsButton from '../buttons/MoreOptionsButton.vue'
import Vue, { PropOptions } from 'vue'
import { ActionDefinition, ColumnDefinition } from '@/shared/types/DataTable'

function getInvertedOrder(order: 'asc' | 'desc') {
  return order === 'asc' ? 'desc' : 'asc'
}

export default Vue.extend({
  name: 'DataCollection',
  components: {
    ActionDropdown,
    IntersectionObserver,
    MoreOptionsButton,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    } as PropOptions<any[]>,
    keyField: {
      type: String,
      default: 'id',
    } as PropOptions<string>,
    loadedAll: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    loading: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    sorting: {
      type: Object,
      required: false,
      default: null,
    } as PropOptions<{
      order?: 'asc' | 'desc'
      sort?: string
    } | null>,
    /**
     * @type { {name: string}, {key: string} }[]
     */
    columns: {
      type: Array,
      default: () => [],
    } as PropOptions<ColumnDefinition[]>,
    actions: {
      type: Array,
      default: () => [],
    } as PropOptions<ActionDefinition[]>,
    /**
     * Item size as bootstrap col class
     */
    itemSize: {
      type: String,
      default: 'col-12',
    } as PropOptions<string>,
    showNoDataLabel: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
  },
  data() {
    return {
      mounted: false,
      sortingIcons: {
        asc: ['fad', 'sort-amount-up'],
        desc: ['fad', 'sort-amount-down'],
      } as { [key: string]: [string, string] },
    }
  },
  computed: {
    /**
     * Map Sorting columns to actions
     */
    sortingActions(): ActionDefinition[] {
      if (!this.sorting) return []
      return this.columns.map((column) => {
        return {
          name: column.name,
          handler: () => {
            // invert order
            let sort = column.key
            let order =
              sort === this.sorting!.sort
                ? getInvertedOrder(this.sorting!.order!)
                : 'asc'
            this.$emit('setSorting', { sort, order })
          },
          icon:
            this.sorting!.sort === column.key
              ? this.sortingIcons[getInvertedOrder(this.sorting!.order!)]
              : this.sortingIcons['asc'],
        }
      }) as ActionDefinition[]
    },
    /**
     * Get Active sorting name
     */
    activeSortingName(): string {
      let column = this.columns.find(
        (column) => column.key === this.sorting?.sort
      )
      if (column) return column.name
      else return ''
    },
  },
  mounted() {
    this.mounted = true
  },
})

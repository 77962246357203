
import Vue, { PropType } from 'vue'
import IconWithBackground from '@/shared/components/utils/IconWithBackground.vue'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'

export default Vue.extend({
  name: 'CommunityBadges',
  components: {
    IconWithBackground,
  },
  props: {
    communities: {
      type: Array as PropType<Community[]>,
      required: true,
    },
  },
})


import ResponsiveImage from '../media/ResponsiveImage.vue'
import CommunityBadges from '@/shared/components/communities/CommunityBadges.vue'
import CircleImage from '@/shared/components/media/CircleImage.vue'
import ResourceProperties from '@/bookingbuddy-shop/components/resources/ResourceProperties.vue'
import Vue, { PropOptions } from 'vue'
import { Resource } from '../../jsonapi-orm/bookingbuddy/Resource'
import { Image } from '../../jsonapi-orm/common/Image'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Service } from '@/shared/jsonapi-orm/bookingbuddy/Service'

export default Vue.extend({
  name: 'OrmResourcePanel',
  components: {
    ResourceProperties,
    CircleImage,
    ResponsiveImage,
    CommunityBadges,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    } as PropOptions<Resource>,
    selectable: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    showQuantity: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    showOrganization: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    showLocation: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    showStreet: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    organizationProp: {
      type: Object,
      default: null,
      required: false,
    } as PropOptions<Organization | null>,
    /**
     * Indicated if panel is a call to action
     */
    ctaStyle: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
  },
  computed: {
    coverImage(): Image | null {
      if (this.resource && this.resource.coverImage) {
        return this.resource.coverImage
      } else {
        return null
      }
    },
    minimalService(): Service | null {
      return this.resource.minimalService
    },
    isRentable(): boolean {
      // do NOT use || instead of ??
      return this.resource.category?.isRentable ?? true
    },
    displayQuantity(): boolean {
      return (
        this.showQuantity ||
        !(this.resource.metaSettings.showAvailabilityCount === false)
      )
    },
    organization(): Organization | null {
      return this.organizationProp ?? this.resource.organization ?? null
    },
  },
})


import LazyFa from '../utils/LazyFa.vue'
import Vue, { PropOptions } from 'vue'
import { ResourceProperty } from '@/shared/jsonapi-orm/bookingbuddy/ResourceProperty'
import { Property } from '@/shared/jsonapi-orm/bookingbuddy/Property'

export default Vue.extend({
  name: 'ResourceProperty',
  components: { LazyFa },
  props: {
    /**
     * ResourceProperty API Resource
     * { value, property: { label, icon, value_type } }
     */
    resourceProperty: {
      type: Object,
      required: true,
    } as PropOptions<ResourceProperty>,
    hideValue: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    small: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    pill: {
      type: Boolean,
      default: false,
    },
    monochrome: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    /**
     * Property API Resource
     * { label, icon, value_type }
     */
    property(): Property | null {
      return this.resourceProperty.property as Property | null
    },
  },
  methods: {},
})

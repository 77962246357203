<template>
  <div
    class="eb-panel eb-resource-panel d-flex flex-column w-100 flex-sm-row mt-3"
  >
    <!-- Dummy Image-->
    <div
      v-if="!withoutImage"
      class="eb-placeholder-gradient eb-resource-panel-image"
    >
      <!--<p>Hello!</p>-->
    </div>
    <!-- Dummy Information -->
    <div class="d-flex flex-column flex-fill p-3">
      <div
        class="eb-placeholder-gradient pt-2"
        style="height: 40px; width: 100%"
      ></div>
      <div
        class="eb-placeholder-gradient mt-2"
        style="height: 25px; width: 100%"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingResourcePanel',
  props: {
    withoutImage: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.eb-resource-panel {
  &-image {
    min-width: 300px;
    min-height: 200px;
    border-radius: var(--panel-border-radius) 0 0 var(--panel-border-radius);
    @media screen and (max-width: 576px) {
      width: 100%;
      height: 200px;
      border-radius: var(--panel-border-radius) var(--panel-border-radius) 0 0;
    }
  }
}
</style>


import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'RegisterHint',
  props: {
    givenName: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
    familyName: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
    email: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
    showCta: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  computed: {
    /**
     * Build url with prefilled data.
     */
    registerLink(): string {
      let name = ''
      let email = ''
      if (this.givenName) {
        name = this.givenName + ' ' + this.familyName
      }
      if (this.email) {
        email = this.email
      }

      const path = this.localePath({
        name: 'register',
        query: {
          target: this.$route.fullPath,
          email,
          name,
        },
      })
      return this.$config.baseUrl + path
    },
  },
})

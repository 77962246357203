
import Vue, { PropType } from 'vue'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import ResourcePropertyComp from '@/shared/components/resources/ResourceProperty.vue'
import { ResourceProperty } from '@/shared/jsonapi-orm/bookingbuddy/ResourceProperty'

export default Vue.extend({
  name: 'ResourceProperties',
  components: {
    ResourcePropertyComp,
  },
  props: {
    resource: {
      type: Object as PropType<Resource | any>,
      required: true,
    },
    maxItems: {
      type: Number as PropType<number | null>,
      default: null,
      required: false,
    },
    monochrome: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      tmpMaxItems: this.maxItems,
    }
  },
  computed: {
    resourceProperties(): ResourceProperty[] {
      return this.resource.resourceProperties ?? []
    },
    visibleProperties(): ResourceProperty[] {
      if (this.tmpMaxItems !== null) {
        return this.resourceProperties.slice(0, this.tmpMaxItems)
      }
      return this.resourceProperties
    },
    hiddenProperties(): ResourceProperty[] {
      if (this.tmpMaxItems !== null) {
        return this.resourceProperties.slice(this.tmpMaxItems)
      }
      return []
    },
  },
  methods: {
    expandProperties() {
      this.tmpMaxItems = null
    },
    minimizeProperties() {
      this.tmpMaxItems = this.maxItems
    },
  },
})
